<template>
    <div>
        <div class='borderCard flex-center p12'>
            <div class='card-center'>
                <el-form ref="companyForm" label-position="right" :rules='rules' :model="form" label-width="140px">
                    <div class='pageTitle'><span class='icon'></span>企业信息</div>
                    <el-row>
                        <el-col :span='span'>
                            <el-form-item label="企业名称：" prop="companyName">
                                <el-input v-model='form.companyName' placeholder='请输入企业营业执照上的名称，务必保持一致'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span='span'>
                            <el-form-item label="营业执照扫描件：" prop="licenseId">
                                <el-upload
                                    class="avatar-uploader"
                                    action=""
                                    :show-file-list="false"
                                    :http-request='uploadFile'
                                    :on-change='changeFile'
                                    :on-success="handleAvatarSuccess"
                                >
                                        <img v-if="imageUrl" :src="imageUrl" class="avatar">
                                        <i v-else class="el-icon-plus avatar-uploader-icon"></i>
                                </el-upload>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span='span'>
                            <el-form-item label="所属行业：" prop="industry">
                                <el-select v-model="form.industry" placeholder="请选择所属行业">
                                    <el-option label="1" :value="1"></el-option>
                                    <el-option label="2" :value="2"></el-option>
                                </el-select>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span='span'>
                            <el-form-item label="官网地址：" prop="companyUrl">
                                <el-input v-model='form.companyUrl' placeholder='请输入您公司的官网地址'></el-input>
                             </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span='span'>
                            <el-form-item label="公司简介：" prop="companyDetail">
                                <el-input type="textarea" v-model='form.companyDetail' maxlength="500" show-word-limit :autosize="{ minRows: 3, maxRows: 8}" placeholder='请填写您公司的简介及业务描述，限制500字'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span='span'>
                            <el-form-item label="公司地址：" prop="address">
                                <el-input v-model='form.address' placeholder='请输入公司地址'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span='span'>
                            <el-form-item label="资质荣誉：" >
                                <el-upload
                                    action=""
                                    class="upload-demo"
                                    drag
                                    :on-change='changeFileList'
                                    :file-list='fileList'
                                    :http-request='uploadFiles'
                                    multiple>
                                    <i class="el-icon-upload"></i>
                                    <div class="el-upload__text">将文件拖到此处，或<em>点击上传</em></div>
                                    <div class="el-upload__tip" slot="tip">只能上传.jpg .png .jpeg文件</div>
                                </el-upload>
                            </el-form-item>
                        </el-col>
                    </el-row>

                    <div class='pageTitle'><span class='icon'></span>联系人信息</div>
                    <el-row>
                        <el-col :span='span'>
                            <el-form-item label="联系人姓名：" prop="contactName">
                                <el-input v-model='form.contactName' placeholder='请输入姓名'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span='span'>
                            <el-form-item label="联系人职务：" prop="contactPosition">
                                <el-input v-model='form.contactPosition' placeholder='请输入联系人职务'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span='span'>
                            <el-form-item label="联系人电话：" prop="contactPhone">
                                <el-input v-model='form.contactPhone' placeholder='输入的手机号码务必于当前注册的手机号保持一致'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                    <el-row>
                        <el-col :span='span'>
                            <el-form-item label="联系人邮箱：" prop="email">
                                <el-input v-model='form.email' placeholder='请输入联系人邮箱'></el-input>
                            </el-form-item>
                        </el-col>
                    </el-row>
                </el-form>
                <div style='text-align:center;margin:16px 0;'>
                    <el-button @click='cancel' icon='el-icon-circle-close'> 取 消 </el-button>
                    <el-button @click='submitForm' type='primary' icon='el-icon-circle-check' v-if='$route.query.type == 1'> 提 交 </el-button>
                    <el-button @click='handleModify' type='primary' v-if='$route.query.type == 2'>修 改</el-button>
                </div>
            </div>
        </div>
    </div>
</template>


<script>
import api from '@/api/api'

export default {
    data(){
        return{
            isCreate:true,
            form:{},
            span:22,
            file:'',
            fileList:[],
            imageUrl:'',
            rules:{
                companyName: [
                    { required: true, message: '请填写必填项', trigger: 'blur' },
                ],
                companyDetail: [
                    { required: true, message: '请填写必填项', trigger: 'blur' },
                ],
                address: [
                    { required: true, message: '请填写必填项', trigger: 'blur' },
                ],
                contactName: [
                    { required: true, message: '请填写必填项', trigger: 'blur' },
                ],
                contactPosition: [
                    { required: true, message: '请填写必填项', trigger: 'blur' },
                ],
                contactPhone: [
                    { required: true, message: '请填写必填项', trigger: 'blur' },
                ],
                email: [
                    { required: true, message: '请填写必填项', trigger: 'blur' },
                ],
            }
        }
    },
    mounted(){
        if(this.$route.query.type == 2){
            this.queryCompanyDetail()
        }
    },
    methods:{
        queryCompanyDetail(){
            let id = JSON.parse(window.localStorage.getItem('ecolUserInfo')).companyId
            api.queryCompanyDetail(id).then(res => {
                if(res.code == 200){
                     this.form = res.data   
                }
            })
        },
        uploadFile(){

        },
        handleAvatarSuccess(res, file) {
            this.imageUrl = URL.createObjectURL(file.raw);
        }, 
        changeFile(file){
            this.file = file
            this.imageUrl = URL.createObjectURL(file.raw);

        },
        uploadFiles(){

        },
        changeFileList(file,fileList){
            this.fileList = fileList
        },
        submitForm() {
            this.$refs.companyForm.validate((valid) => {
                if (valid) {
                    this.loading = true
                    let formData = new FormData()
                    for (var key in this.form) {
                        if (Object.prototype.hasOwnProperty.call(this.form, key)) {
                            formData.append(key, this.form[key]);
                        }
                    }
                    if(this.file) formData.append('file', this.file.raw);
                    if(this.fileList && this.fileList.length > 0){
                        let files = this.fileList.map((item,index) => {
                            formData.append(`files`, item.raw)
                        })
                    }
                    // if(this.fileList && this.fileList.length > 0){
                    //     let files = this.fileList.map((item,index) => {
                    //         return item.raw
                    //     })
                    //     formData.append(`files`, files)
                    // }

                    api.createCompany(formData).then(res => {
                        this.loading = false
                        if(res.code == 200){
                            window.localStorage.setItem('company',JSON.stringify(res.data))
                            this.$confirm('认证企业成功，是否需要继续添加？', '成 功', {
                                confirmButtonText: '继续添加',
                                cancelButtonText: '返回列表',
                                type: 'success'
                            }).then(() => {
                                this.reset()
                            }).catch(() => {
                                this.cancel()
                            });
                        }else{
                            this.$notify({
                                title: '提 示',
                                message: res.msg,
                                type: 'warning'
                            });
                        }
                    })
                    .catch(err => {
                        this.loading = false
                    })
                } else {
                    return false;
                }
            });
        },
        reset(){
            this.file = ''
            this.fileList = []
            this.form = {}
            this.companyVisible = false
            this.$refs.companyForm.resetFields();
        },
        cancel(){
            this.reset()
            this.$router.back()
        },
        handleModify(){
            api.companyModify(this.form).then(res => {

            })
        }
    }
}
</script>

<style>
  .avatar-uploader .el-upload {
    border: 1px dashed #d9d9d9;
    border-radius: 6px;
    cursor: pointer;
    position: relative;
    overflow: hidden;
  }
  .avatar-uploader .el-upload:hover {
    border-color: #409EFF;
  }
  .avatar-uploader-icon {
    font-size: 28px;
    color: #8c939d;
    width: 140px;
    height: 140px;
    line-height: 140px;
    text-align: center;
  }
  .avatar {
    width: 140px;
    height: 140px;
    display: block;
    }
    .card-center{
    width:800px;
    }
    .card-title{
        line-height: 32px;
        font-size: 16px;
        font-weight: 600;
        font-family: '黑体';
    }
    .borderCard {
        border: 1px solid #f3f4f5 !important;
        padding: 16px 24px !important;
        margin-top: 12px;
        border-radius: 8px;
        background-color: #fff  ;
    
        .el-card__body {
            padding: 0;
        }
    }
    </style>